import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import {DEFAULT_EVENT_BG_COLOR, GCAL_URL, TRACK_OPEN_POPUP} from './constants';
import {handleAnalytics} from './analytics';
import {getOverviewEvents} from '../helpers';

export const CHANGE_DATE = 'CHANGE_DATE';
export const SET_DATE = 'SET_DATE';
export const SET_EVENTS = 'SET_EVENTS';
export const EVENT_POPUP_UPDATE = 'EVENT_POPUP_UPDATE';
export const UPDATE_VIEWPORT = 'UPDATE_VIEWPORT';

export const setDateAC = date => ({
  type: SET_DATE,
  payload: {date}
});

export const changeDateAC = data => ({
  type: CHANGE_DATE,
  payload: {
    data
  }
});

function processEventTitle(title) {
  const noMatchResult = {title, color: DEFAULT_EVENT_BG_COLOR};
  if (!title) {
    return noMatchResult;
  }
  const colorRegExp = /#([0-9A-F]{6}|[0-9A-F]{3})/im;
  const matches = colorRegExp.exec(title);
  if (!matches) {
    return noMatchResult;
  }
  return {title: _.replace(title, colorRegExp, ''), color: matches[0]};
}

export const fetchEventsAC = () => {
  return (dispatch, getState) => {
    const updatedAt = _.get(getState(), 'updatedAt');
    if (updatedAt) {
      const diffInMinutes = moment().diff(moment(updatedAt), 'minutes');
      if (diffInMinutes <= 60) {
        document.querySelector('.preloader').style.display = 'none';
        return;
      }
    }
    return axios.get(GCAL_URL).then(res => {
      let events = _.map(res.data.items, item => {
        const {title, color} = processEventTitle(_.get(item, 'summary'));
        return {
          start: item.start.date || item.start.dateTime,
          end: item.end.date || item.end.dateTime,
          withTime: Boolean(item.start.dateTime),
          title,
          description: item.description,
          color
        };
      });
      dispatch({
        type: SET_EVENTS,
        payload: {
          events,
          updatedAt: moment().toISOString(),
          overviewEvents: getOverviewEvents(events)
        }
      })
    }).then(res => {
      window.location.reload();
    });
  };
};

export const eventPopupUpdateAC = data => {
  if (_.get(data, 'isOpen')) {
    handleAnalytics({event: TRACK_OPEN_POPUP});
  }

  return {
    type: EVENT_POPUP_UPDATE,
    payload: {
      data
    }
  };
};

export const updateViewportAC = data => {
  return {
    type: UPDATE_VIEWPORT,
    payload: {
      data
    }
  };
};
