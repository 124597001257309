import wrapWithProvider from './src/helpers/wrap-with-provider';
import {handleAnalytics} from './src/shared/analytics';
import {TRACK_SESSION_START} from './src/shared/constants';
import {version} from './package';

const hidePreloader = () => {
  const preloader = document.querySelector('.preloader');
  if (!preloader) {
    return;
  }
};

console.log('Version:', version);
handleAnalytics({event: TRACK_SESSION_START});
hidePreloader();

export const wrapRootElement = wrapWithProvider;
