import moment from 'moment';
import {REHYDRATE} from 'redux-persist';

import {
  CHANGE_DATE,
  EVENT_POPUP_UPDATE,
  SET_DATE,
  SET_EVENTS,
  UPDATE_VIEWPORT
} from './actions';

export default function(state, action) {
  switch (action.type) {
    case REHYDRATE: {
      return {
        ...state,
        eventPopup: {}
      };
    }
    case CHANGE_DATE: {
      let {interval, value} = action.payload.data;
      let newDate = moment(state.date)
        .clone()
        .add(value, interval);
      return {...state, date: newDate.toISOString()};
    }

    case SET_DATE: {
      let newDate = moment(action.payload.date).clone();
      return {...state, date: newDate.toISOString()};
    }

    case SET_EVENTS: {
      return {
        ...state,
        events: action.payload.events,
        updatedAt: action.payload.updatedAt,
        overviewEvents: action.payload.overviewEvents
      };
    }

    case EVENT_POPUP_UPDATE: {
      return {...state, eventPopup: action.payload.data};
    }

    case UPDATE_VIEWPORT: {
      return {...state, viewport: action.payload.data};
    }

    default:
      return state;
  }
}
