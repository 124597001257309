import _ from 'lodash/core';
import times from 'lodash/times';
import map from 'lodash/map';
import reverse from 'lodash/reverse';
import moment from 'moment';
import {
  HOUR_START,
  WORK_HOURS,
  DAYS_IN_WEEK,
  WEEKS_IN_MONTH,
  HEADER_HEIGHT_MOBILE,
  FOOTER_HEIGHT,
  HEADER_HEIGHT
} from '../shared/constants';

export function isServer() {
  return typeof window === 'undefined';
}

export function getCssHeightForContent({isMobile}) {
  return `100vh - ${
      isMobile ? HEADER_HEIGHT_MOBILE + FOOTER_HEIGHT : HEADER_HEIGHT
  }px`;
}

export function getDayData(date) {
  let startOfDay = moment(date)
      .clone()
      .startOf('day');
  return map(times(_.chain(WORK_HOURS), item => item + HOUR_START), item =>
      startOfDay
          .clone()
          .add(item, 'hour')
          .toISOString()
  );
}

export function getWeekData(date) {
  let startOfWeek = moment(date)
      .clone()
      .startOf('isoWeek');
  return map(times(_.chain(7)), item =>
      startOfWeek
          .clone()
          .add(item, 'day')
          .toISOString()
  );
}

export function getMonthData(date) {

  let startOfMonth = moment(date)
    .clone()
    .startOf('month');
  let daysInMonth = moment(date)
    .clone()
    .endOf('month')
    .date();

  let days = map(times(_.chain(daysInMonth)), item =>
      startOfMonth
          .clone()
          .add(item, 'day')
          .format('YYYY-MM-DD')
  );
  let padLeftAmount = startOfMonth.clone().isoWeekday() - 1;
  let padRightAmount =
    WEEKS_IN_MONTH * DAYS_IN_WEEK - daysInMonth - padLeftAmount;
  let prevMonthLastDay = moment(_.head(days))
    .clone()
    .subtract(1, 'day');

  let padLeft = reverse(times(_.chain(daysInMonth), leftIndex =>
      prevMonthLastDay
          .clone()
          .subtract(leftIndex, 'day')
          .format('YYYY-MM-DD')));


  let nextMonthFirstDay = moment(_.last(days))
    .clone()
    .add(1, 'day');


  let padRight = times(_.chain(padRightAmount), rightIndex =>
      nextMonthFirstDay
          .clone()
          .add(rightIndex, 'day')
          .format('YYYY-MM-DD'));

  return _.concat(padLeft, days, padRight);
}

export function getYearData(date) {
  let startOfYear = moment(date)
      .clone()
      .startOf('year');
  return map(times(_.chain(12)), item =>
      startOfYear
          .clone()
          .add(item, 'month')
          .toISOString()
  );
}

export function isToday(date) {
  return moment(date).isSame(moment(), 'day');
}

export function getNormalizedDayEvents(date, events) {
  const dateMoment = moment(date);
  const workDayStart = dateMoment
      .clone()
      .startOf('day')
      .add(HOUR_START, 'hour');
  const workDayEnd = workDayStart.clone().add(WORK_HOURS, 'hour');
  return _.chain(events)
      .filter(event => {
        let start = moment(event.start);
        let end = moment(event.end);
        return (
            start.isSame(dateMoment, 'day') ||
            (end.isSame(dateMoment, 'day') && event.withTime) ||
            (start.isBefore(dateMoment) && end.isAfter(dateMoment))
        );
      })
      .map(event => {
        let normalizedEvent = _.clone(event);
        if (moment(event.start).isBefore(workDayStart)) {
          normalizedEvent.start = workDayStart.format();
        }
        if (moment(event.end).isAfter(workDayEnd)) {
          normalizedEvent.end = workDayEnd.format();
        }
        return normalizedEvent;
      })
      .value();
}

function expandEventsAndGroupByDay(acc, event) {
  const startMoment = moment(event.start);
  const endMoment = moment(event.end);
  const dayDiff = endMoment.diff(startMoment, 'day');
  const steps = event.withTime ? dayDiff + 1 : dayDiff;
  times(_.chain(steps),  i => {
        const currentMoment = startMoment.clone().add(i, 'day');
        const existedItem = _.find(acc, item =>
            moment(item.date).isSame(currentMoment, 'day')
        );
        existedItem
            ? existedItem.events.push(event)
            : acc.push({date: currentMoment.format('YYYY-MM-DD'), events: [event]});
      });
  return acc;
}


export function getOverviewEvents(googleEvents) {
  return _.chain(googleEvents)
    .reduce(expandEventsAndGroupByDay, [])
}

export function getDateEvents(date, normalizedEvents) {
  const dayObj = _.find(normalizedEvents, {date});
  return dayObj ? dayObj.events : [];
}
