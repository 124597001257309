import React from 'react';
import PropTypes from 'prop-types';
import {Provider} from 'react-redux';
import {PersistGate} from 'redux-persist/integration/react';

import createStore from '../shared/createStore';

export default function WrapWithProvider({element}) {
  const {store, persistor} = createStore();
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {element}
      </PersistGate>
    </Provider>
  );
}

WrapWithProvider.propTypes = {
  element: PropTypes.object.isRequired
};
