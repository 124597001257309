import _ from 'lodash';
import {
  TRACK_CLOSE_POPUP,
  TRACK_EMAIL_CLICK,
  TRACK_NAVIGATION_DAY,
  TRACK_NAVIGATION_MONTH,
  TRACK_NAVIGATION_OVERVIEW,
  TRACK_NAVIGATION_WEEK,
  TRACK_NAVIGATION_YEAR,
  TRACK_OPEN_POPUP,
  TRACK_SESSION_START
} from './constants';

export const handleAnalytics = ({event}) => {
  const ga = window.ga || _.noop;
  const fbq = window.fbq || _.noop;
  switch (event) {
    case TRACK_SESSION_START: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Session',
        eventAction: 'Initialization',
        eventLabel: 'Stage 1'
      });
      fbq('track', 'PageView');
      break;
    }

    case TRACK_OPEN_POPUP: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Description',
        eventAction: 'Open',
        eventLabel: 'Stage 1'
      });
      fbq('track', 'ViewContent', {
        content_name: 'Information',
        content_type: 'Cases'
      });
      break;
    }

    case TRACK_CLOSE_POPUP: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Description',
        eventAction: 'Close',
        eventLabel: 'Stage 1'
      });
      break;
    }

    case TRACK_NAVIGATION_DAY: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Navigation',
        eventAction: 'Click',
        eventLabel: 'Stage 1'
      });
      fbq('track', 'ViewContent', {
        content_name: 'Navigation',
        content_type: 'Day navigation'
      });
      break;
    }

    case TRACK_NAVIGATION_WEEK: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Navigation',
        eventAction: 'Click',
        eventLabel: 'Stage 1'
      });
      fbq('track', 'ViewContent', {
        content_name: 'Navigation',
        content_type: 'Week navigation'
      });
      break;
    }

    case TRACK_NAVIGATION_MONTH: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Navigation',
        eventAction: 'Click',
        eventLabel: 'Stage 1'
      });
      fbq('track', 'ViewContent', {
        content_name: 'Navigation',
        content_type: 'Month navigation'
      });
      break;
    }

    case TRACK_NAVIGATION_YEAR: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Navigation',
        eventAction: 'Click',
        eventLabel: 'Stage 1'
      });
      fbq('track', 'ViewContent', {
        content_name: 'Navigation',
        content_type: 'Year navigation'
      });
      break;
    }

    case TRACK_NAVIGATION_OVERVIEW: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Navigation',
        eventAction: 'Click',
        eventLabel: 'Stage 1'
      });
      fbq('track', 'ViewContent', {
        content_name: 'Navigation',
        content_type: 'Overview navigation'
      });
      break;
    }

    case TRACK_EMAIL_CLICK: {
      ga('send', {
        hitType: 'event',
        eventCategory: 'Feedback form',
        eventAction: 'Click',
        eventLabel: 'Stage 1'
      });
      break;
    }

    default:
      break;
  }
};
