exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-day-js": () => import("./../../../src/pages/day.js" /* webpackChunkName: "component---src-pages-day-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-month-js": () => import("./../../../src/pages/month.js" /* webpackChunkName: "component---src-pages-month-js" */),
  "component---src-pages-overview-js": () => import("./../../../src/pages/overview.js" /* webpackChunkName: "component---src-pages-overview-js" */),
  "component---src-pages-week-js": () => import("./../../../src/pages/week.js" /* webpackChunkName: "component---src-pages-week-js" */),
  "component---src-pages-year-js": () => import("./../../../src/pages/year.js" /* webpackChunkName: "component---src-pages-year-js" */)
}

