const CALENDAR_ID = 'epq7b9ukfdahd9onslkkma4k9c@group.calendar.google.com';
const GOOGLE_API_KEY = 'AIzaSyAlKgcNbwCCzEI7fln92CDObKgOqe1eADs';
export const GCAL_URL = `https://www.googleapis.com/calendar/v3/calendars/${CALENDAR_ID}/events?key=${GOOGLE_API_KEY}&maxResults=5000`;

export const HOUR_START = 10;
export const WORK_HOURS = 10;
export const DAYS_IN_WEEK = 7;
export const WEEKS_IN_MONTH = 6;

export const FONT_SIZE = 14;
export const FONT_SIZE_MOBILE = 15;
export const TEXT_COLOR = '#000';
export const DEFAULT_EVENT_BG_COLOR = '#000';
export const DEFAULT_EVENT_TEXT_COLOR = '#fff';
export const FOOTER_BG_COLOR = '#fff';
export const DAY_NAV_BORDER_COLOR = '#000';
export const MONTH_PREVIEW_COLOR = '#aaa';

export const HEADER_HEIGHT_MOBILE = 60;
export const HEADER_HEIGHT = 120;
export const LAYOUT_SELECTOR_WIDTH = '40%';
export const OVERVIEW_EVENT_HEIGHT = 90;
export const DAY_CELL_MIN_HEIGHT = 37;
export const MONTH_BLOCK_WIDTH = 260;
export const MONTH_BLOCK_WIDTH_S = '46%';
export const FOOTER_HEIGHT = 50;
export const YEAR_GRID_MAX_WIDTH = 1600;
export const HOUR_COLUMN_WIDTH = 60;

export const POPUP_BG_COLOR = '#000';
export const POPUP_WIDTH = 300;
export const POPUP_TEXT_COLOR = '#fff';
export const POPUP_CLOSE_BUTTON_FONT_SIZE = '1.2rem';
export const POPUP_CLOSE_BUTTON_FONT_SIZE_MOBILE = '2rem';

export const GAP_XXS = 5;
export const GAP_XS = 15;
export const GAP_S = 20;
export const GAP_M = 25;
export const GAP_L = 35;
export const GAP_XL = 45;
export const GAP_XXL = 50;
export const GAP_XXXL = 60;

export const MEDIUM_BREAKPOINT = '768px';

export const Z_INDEX_DAY_CELL_TITLE = 1;
export const Z_INDEX_FOOTER = 5;
export const Z_INDEX_EVENT_POPUP = 10;

export const LAYOUT_DAY = 'LAYOUT_DAY';
export const LAYOUT_WEEK = 'LAYOUT_WEEK';
export const LAYOUT_MONTH = 'LAYOUT_MONTH';
export const LAYOUT_YEAR = 'LAYOUT_YEAR';

export const TRACK_SESSION_START = 'TRACK_SESSION_START';
export const TRACK_OPEN_POPUP = 'TRACK_OPEN_POPUP';
export const TRACK_CLOSE_POPUP = 'TRACK_CLOSE_POPUP';
export const TRACK_NAVIGATION_DAY = 'TRACK_NAVIGATION_DAY';
export const TRACK_NAVIGATION_WEEK = 'TRACK_NAVIGATION_WEEK';
export const TRACK_NAVIGATION_MONTH = 'TRACK_NAVIGATION_MONTH';
export const TRACK_NAVIGATION_YEAR = 'TRACK_NAVIGATION_YEAR';
export const TRACK_NAVIGATION_OVERVIEW = 'TRACK_NAVIGATION_OVERVIEW';
export const TRACK_EMAIL_CLICK = 'TRACK_EMAIL_CLICK';
