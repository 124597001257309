import {createStore as reduxCreateStore, applyMiddleware} from 'redux';
import {persistStore, persistReducer} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import {composeWithDevTools} from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import moment from 'moment';

import reducer from './reducer';

const persistConfig = {
  key: 'root',
  storage
};

const initialState = {
  date: moment().toISOString(),
  events: [],
  eventPopup: {
    isOpen: false,
    description: '',
    x: 0,
    y: 0
  },
  viewport: {
    isMobile: false
  },
  updatedAt: null
};

const createStore = () => {
  let store = reduxCreateStore(
    persistReducer(persistConfig, reducer),
    initialState,
    composeWithDevTools(applyMiddleware(thunk))
  );
  let persistor = persistStore(store);
  return {store, persistor};
};

export default createStore;
